export const GET_DROPDOWN_DATA = "GET_DROPDOWN_DATA";
export const SET_DROPDOWN_DATA = "SET_DROPDOWN_DATA";
export const UPLOAD_FILE = "UPLOAD_FILE";
export const SET_IMAGE_DATA = "SET_IMAGE_DATA";
export const CREATE_PODCAST = "CREATE_PODCAST";
export const UPDATE_PODCAST = "UPDATE_PODCAST";
export const TOGGLE_AUDIO_LOADER = "TOGGLE_AUDIO_LOADER";
export const SET_EPISODE_MEATADATA = "SET_EPISODE_MEATADATA";
export const GET_PODCAST = "GET_PODCAST";
export const SET_PODCAST = "SET_PODCAST";
export const SET_PODCAST_ID = "SET_PODCAST_ID";
//episodes
export const CREATE_EPISODE = "CREATE_EPISODE";
export const GET_EPISODES = "GET_EPISODES";
export const SET_EPISODES = "SET_EPISODES";
// publish podcast
export const PUBLISH_PODCAST = "PUBLISH_PODCAST";
export const TOGGLE_RSS_LOADER = "TOGGLE_RSS_LOADER";
export const SET_RSS_DATA = "SET_RSS_DATA";
export const CLEAR_REDUCER = "CLEAR_REDUCER";
export const VIOLATIONS_RETRY = "VIOLATIONS_RETRY";
